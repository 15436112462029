import React from 'react'
import PropTypes from 'prop-types'

import AddressLocality from './address-locality'
import BuyTickets from './buy-tickets'
import EventTime from './event-time'
import EventType from './event-type'
import TourName from './tour-name'
import VenueName from './venue-name'

const EventsTableRow = ({ condensed, event, showTickets }) => (
  <tr>
    <td className='align-middle'>
      <EventTime event={event} />
    </td>
    {condensed === false && (
      <>
        <td className='align-middle d-none d-sm-table-cell'>
          <EventType event={event} />
        </td>
        <td className='align-middle d-none d-sm-table-cell'>
          <TourName tour={event.frontmatter.tour} />
        </td>
      </>
    )}
    <td className='align-middle'>
      <AddressLocality address={event.frontmatter.venue.frontmatter.address} />
    </td>
    <td className='align-middle'>
      <VenueName venue={event.frontmatter.venue} />
    </td>
    {showTickets === true && (
      <>
        <td className='align-middle'>
          {
            <BuyTickets
              ticketURL={event.frontmatter.ticketURL}
              status={event.frontmatter.status}
              isSmall={true}
            />
          }
        </td>
      </>
    )}
  </tr>
)

EventsTableRow.propTypes = {
  condensed: PropTypes.bool,
  event: PropTypes.shape({
    frontmatter: PropTypes.shape({
      status: PropTypes.oneOf([
        'Cancelled',
        'Free',
        'On Sale',
        'Postponed',
        'Sold Out',
        'Unavailable',
      ]),
      ticketURL: PropTypes.string,
      tour: PropTypes.object,
      venue: PropTypes.shape({
        frontmatter: PropTypes.shape({
          address: PropTypes.object.isRequired,
          displayName: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }),
  }).isRequired,
  showTickets: PropTypes.bool,
}

EventsTableRow.defaultProps = {
  condensed: false,
  showTickets: true,
}

export default EventsTableRow
