import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

import FacebookSEO from './facebook/seo'
import TwitterSEO from './twitter/seo'

const SEO = ({ article, description, image, pathname, title }) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultImage,
      defaultTitle,
      defaultDescription,
      facebookAppId,
      language,
      locale,
      siteName,
      siteUrl,
      titleTemplate,
      twitterUsername,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname || '/'}`,
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang={language} />
        <meta name='description' content={seo.description} />
        <meta name='image' content={seo.image} />
      </Helmet>

      <FacebookSEO
        appId={facebookAppId}
        desc={seo.description}
        image={seo.image}
        locale={locale}
        name={siteName}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
      />

      <TwitterSEO
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        username={twitterUsername}
      />
    </>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultImage: image
        defaultTitle: title
        defaultDescription: description
        facebookAppId
        language
        locale
        siteName: title
        siteUrl
        titleTemplate
        twitterUsername
      }
    }
  }
`

SEO.propTypes = {
  article: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

SEO.defaultProps = {
  article: false,
  description: null,
  image: null,
  pathname: null,
  title: null,
}
