import React from 'react'
import PropTypes from 'prop-types'

const EventType = ({ event }) => (
  <span className='text-capitalize'>{event.frontmatter.type}</span>
)

EventType.propTypes = {
  event: PropTypes.shape({
    frontmatter: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default EventType
