import React from 'react'
import PropTypes from 'prop-types'

import BreadcrumbItem from './breadcrumb-item'
import BreadcrumbItemActive from './breadcrumb-item-active'

const Breadcrumb = ({ items, activeItem }) => (
  <nav aria-label='breadcrumb'>
    <ol className='breadcrumb'>
      <BreadcrumbItem to='/'>Home</BreadcrumbItem>
      {constructItems(items)}
      {constructActiveItem(activeItem)}
    </ol>
  </nav>
)

const constructActiveItem = (activeItem) => {
  if (!activeItem) return null

  return <BreadcrumbItemActive>{activeItem}</BreadcrumbItemActive>
}

const constructItems = (items) => {
  return items.map(({ text, to }) => (
    <BreadcrumbItem to={to} key={text}>
      {text}
    </BreadcrumbItem>
  ))
}

Breadcrumb.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
}

Breadcrumb.defaultProps = {
  items: [],
}

export default Breadcrumb
