import React from 'react'
import { graphql, Link } from 'gatsby'

import Breadcrumb from '../components/breadcrumb/breadcrumb'
import EventsTable from '../components/events/events-table'
import Layout from '../components/layout/layout'
import PageHeader from '../components/layout/page-header'
import SEO from '../components/seo'
import heroImage from '../images/the-lancashire-hotpots-live-black-and-white.jpg'

const EventsPage = ({ data }) => {
  const img = {
    src: heroImage,
    alt: 'The Lancashire Hotpots live at Kendal Calling',
  }

  const events = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title='Events' />

      <Layout img={img}>
        <Breadcrumb activeItem='Previous Events' />

        <PageHeader>Previous Events</PageHeader>

        <EventsTable events={events} type='previous' />

        <Link to='/events/' className='btn btn-block btn-sm btn-warning'>
          View Upcoming Events
        </Link>
      </Layout>
    </>
  )
}

export default EventsPage

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "events" } }
        frontmatter: { status: { in: ["Free", "On Sale", "Sold Out"] } }
      }
      sort: { fields: frontmatter___datetime, order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            datetime
            status
            ticketURL
            type
            tour {
              frontmatter {
                tourId
              }
            }
            venue {
              frontmatter {
                displayName
                address {
                  street
                  locality
                  region
                  postcode
                }
                email
                telephone
                websiteURL
              }
            }
          }
        }
      }
    }
  }
`
