import React from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

const Time = ({ format, locale, time }) => (
  <time dateTime={time}>
    {DateTime.fromISO(time)
      .setLocale(locale)
      .toLocaleString(format)}
  </time>
)

Time.propTypes = {
  format: PropTypes.oneOfType([
    PropTypes.shape({
      day: PropTypes.string,
      hour: PropTypes.string,
      minute: PropTypes.string,
      month: PropTypes.string,
      year: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  locale: PropTypes.string,
  time: PropTypes.string.isRequired,
}

Time.defaultProps = {
  format: DateTime.DATETIME_SHORT,
  locale: 'en-gb',
}

export default Time
