import React from 'react'
import PropTypes from 'prop-types'
import fbRegisterLogEvent from '../../utils/fb-register-log-event'

const STATUS_CANCELLED = 'Cancelled'
const STATUS_FREE = 'Free'
const STATUS_ON_SALE = 'On Sale'
const STATUS_POSTPONED = 'Postponed'
const STATUS_SOLD_OUT = 'Sold Out'
const STATUS_UNAVAILABLE = 'Unavailable'

const BuyTickets = ({ isSmall, status, ticketURL }) => {
  if (!ticketURL) {
    ticketURL = '#'

    if (status === STATUS_ON_SALE) {
      status = STATUS_UNAVAILABLE
    }
  }

  const options = getOptions(status)

  const params = {}
  params['EVENT_TICKET_URL'] = ticketURL

  return (
    <a
      className={getClassNames(status, isSmall)}
      href={ticketURL}
      role='button'
      target='_blank'
      rel='noopener noreferrer'
      onClick={fbRegisterLogEvent.bind(this, 'EVENT_TICKET_CLICK', params)}
      {...options}
    >
      {getText(status)}
    </a>
  )
}

const getClassNames = (status, isSmall) => {
  const classNames = ['btn', 'btn-block']

  switch (status) {
    case STATUS_FREE:
      classNames.push('btn-success')
      break

    case STATUS_ON_SALE:
      classNames.push('btn-danger')
      break

    case STATUS_CANCELLED:
    case STATUS_POSTPONED:
    case STATUS_SOLD_OUT:
    case STATUS_UNAVAILABLE:
    default:
      classNames.push('btn-secondary')
      classNames.push('disabled')
      break
  }

  if (isSmall === true) {
    classNames.push('btn-sm')
  }

  return classNames.join(' ')
}

const getOptions = (status, ticketURL) => {
  const options = {}

  if (status !== STATUS_FREE && status !== STATUS_ON_SALE) {
    options['aria-disabled'] = 'true'
  }

  return options
}

const getText = (status) => {
  if (status === STATUS_ON_SALE) {
    return 'Buy Tickets'
  }

  return status
}

BuyTickets.propTypes = {
  isSmall: PropTypes.bool,
  status: PropTypes.oneOf([
    'Cancelled',
    'Free',
    'On Sale',
    'Postponed',
    'Sold Out',
    'Unavailable',
  ]),
  ticketURL: PropTypes.string,
}

BuyTickets.defaultProps = {
  isSmall: false,
}

export default BuyTickets
