import React from 'react'
import { Link } from 'gatsby'

const BreadcrumbItem = ({ children, to }) => (
  <li className='breadcrumb-item'>
    <Link to={to}>{children}</Link>
  </li>
)

export default BreadcrumbItem
