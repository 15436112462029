import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

const FacebookSEO = ({
  appId,
  desc,
  image,
  locale,
  name,
  title,
  type,
  url,
}) => (
  <Helmet>
    <meta property='fb:app_id' content={appId} />
    {name && <meta property='og:site_name' content={name} />}
    <meta property='og:locale' content={locale} />
    <meta property='og:url' content={url} />
    <meta property='og:type' content={type} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={desc} />
    <meta property='og:image' content={image} />
    <meta property='og:image:alt' content={desc} />
  </Helmet>
)

FacebookSEO.propTypes = {
  appId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
}

FacebookSEO.defaultProps = {
  type: 'website',
  name: null,
}

export default FacebookSEO
