import React from 'react'
import PropTypes from 'prop-types'

const VenueName = ({ venue }) => <span>{venue.frontmatter.displayName}</span>

VenueName.propTypes = {
  venue: PropTypes.shape({
    frontmatter: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default VenueName
