import React from 'react'
import PropTypes from 'prop-types'

const AddressLocality = ({ address }) => <span>{address.locality}</span>

AddressLocality.propTypes = {
  address: PropTypes.shape({
    locality: PropTypes.string.isRequired,
  }).isRequired,
}

export default AddressLocality
