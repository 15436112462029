import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import Time from '../time'

const EventTime = ({ event, format }) => (
  <Time time={event.frontmatter.datetime} format={format} />
)

EventTime.propTypes = {
  event: PropTypes.shape({
    frontmatter: PropTypes.shape({
      datetime: PropTypes.string.isRequired,
    }),
  }).isRequired,
  format: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

EventTime.defaultProps = {
  format: DateTime.DATE_FULL,
}

export default EventTime
