import { DateTime } from 'luxon'

const isValidEvent = (event, type) => {
  if (event.frontmatter.status === 'cancelled') return false
  if (type === 'upcoming') return isEventInTheFuture(event)
  if (type === 'previous') return !isEventInTheFuture(event)

  return true
}

const isEventInTheFuture = (event) => {
  const eventDateTime = DateTime.fromISO(event.frontmatter.datetime)
  const now = DateTime.local()

  return eventDateTime >= now
}

const filterEvents = ({ events, limit, type }) => {
  if (typeof limit !== 'number') {
    limit = events.length
  }

  return events.filter(({ node }) => isValidEvent(node, type)).slice(0, limit)
}

export default filterEvents
