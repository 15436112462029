import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import filterEvents from './../../utils/filter-events'
import EventsTableRow from './events-table-row'

const EventsTable = (props) => {
  const [filteredEvents, setFilteredEvents] = useState([])

  useEffect(() => {
    setFilteredEvents(filterEvents(props))
  }, [])

  return (
    <div className='table-responsive'>
      <table className='table table-hover table-sm'>
        <tbody>
          {filteredEvents.map(({ node: event }) => (
            <EventsTableRow
              event={event}
              key={event.id}
              condensed={props.condensed}
              showTickets={props.type === 'upcoming'}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

EventsTable.propTypes = {
  condensed: PropTypes.bool,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ),
  limit: PropTypes.number,
  type: PropTypes.oneOf(['all', 'previous', 'upcoming']),
}

EventsTable.defaultProps = {
  condensed: false,
  type: 'all',
}

export default EventsTable
