import React from 'react'
import PropTypes from 'prop-types'

const TourName = ({ tour }) => {
  if (!tour || !tour.frontmatter || !tour.frontmatter.tourId) return null

  return <span>{tour.frontmatter.tourId}</span>
}

TourName.propTypes = {
  tour: PropTypes.shape({
    frontmatter: PropTypes.shape({
      tourId: PropTypes.string,
    }),
  }),
}

export default TourName
